module.exports = {
    mobileImage: function () {
        var bannerimages = document.getElementsByClassName('pd-hero-banner');

        for (var i = 0; i < bannerimages.length; i++) {
            var bannerimage = bannerimages[i];
            var image = bannerimage.getAttribute('data-imagemobile');

            if (bannerimage && window.matchMedia('(max-width: 768px)').matches && image != 'null') {
                bannerimage.setAttribute('data-bg', image);
            }
        }
    }
};
