var scrollAnimate = require('./scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    
    if (screen.width <= 765) {
        $('.email-signup-message')
            .append(`<div class="alert alert-sm email-signup-alert text-center ${status}"> 
        <div>
            <span class="icon"> 
                <svg>
                    <use xlink:href="${data.svg}"></use>
                </svg>
            </span>
        </div>
        <div class="alert-text">
            ${data.msg}
        </div>
        </div>`);
    }
    else {
        $('.email-signup-message')
            .append(`<div class="alert alert-lg d-flex email-signup-alert text-center ${status}"> 
        <div>
            <span class="icon"> 
                <svg>
                    <use xlink:href="${data.svg}"></use>
                </svg>
            </span>
        </div>
        <div class="alert-text">
            ${data.msg} 
        </div>
        </div>`);
    }


    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('.subscribe-email-btn').on('click', function (e) {
        e.preventDefault();
        $('#subscriptionModal').modal();
    });

    $('.cancel-subscribe-email').on('click', function (e) {
        e.preventDefault();
        $('.close.pull-right').click();
    });

    $('#newsletter-form').on('submit', evt => {
        evt.preventDefault();
        const $form = $(evt.currentTarget);

        // Clean up any previous errors
        $('.is-invalid', $form).removeClass('is-invalid');
        $('.invalid-feedback', $form).empty();

        // Disable the submit button to prevent double-submits
        const $submitBtn = $('.subscribe-email', $form);
        $submitBtn.attr('disabled', true);

        // Validate the email
        const $email = $('[name$="_email"]', $form);
        const validEmail = $email.length && $email.is(':valid') && new RegExp($email.attr('pattern')).test($email.val());
        if (!validEmail) {
            displayMessage({success: false, msg: 'Invalid Email. Please double-check and try again.'}, $submitBtn);
            return;
        }

        const newsletterUrl = $('#newsletterUrl').val();
        if (!newsletterUrl) {
            return;
        }

        $('body').spinner().start();
        $.ajax({
            url: newsletterUrl,
            type: 'post',
            data: {
                'email': $email.val(),
            },
            success: function (data) {
                $('body').spinner().stop();
                displayMessage(data, $submitBtn);
                $email.val('');
            },
            error: function (err) {
                $('body').spinner().stop();
                displayMessage(err, $submitBtn);
            }
        });
    });
};
