/**
 * Populate the Billing Address Summary View
 * @param {string} parentSelector - the top level DOM selector for a unique address summary
 * @param {Object} address - the address data
 */
function populateAddressSummary(parentSelector, address) {
    $.each(address, function (attr) {
        var val = address[attr];
        $('.' + attr, parentSelector).text(val || '');
    });
}

/**
 * returns a formed <option /> element
 * @param {Object} shipping - the shipping object (shipment model)
 * @param {boolean} selected - current shipping is selected (for PLI)
 * @param {order} order - the Order model
 * @param {Object} [options] - options
 * @returns {Object} - the jQuery / DOMElement
 */
function optionValueForAddress(shipping, selected, order, options) {
    var safeOptions = options || {};
    var isBilling = safeOptions.type && safeOptions.type === 'billing';
    var className = safeOptions.className || '';
    var isSelected = selected;
    var isNew = !shipping;
    if (typeof shipping === 'string') {
        return $('<option class="' + className + '" disabled>' + shipping + '</option>');
    }
    var safeShipping = shipping || {};
    var shippingAddress = safeShipping.shippingAddress || {};

    if (isBilling && isNew && !order.billing.matchingAddressId) {
        shippingAddress = order.billing.billingAddress.address || {};
        isNew = false;
        isSelected = true;
        safeShipping.UUID = 'manual-entry';
    }

    var uuid = safeShipping.UUID ? safeShipping.UUID : 'new';
    var optionEl = $('<option class="' + className + '" />');
    optionEl.val(uuid);

    var title;

    if (isNew) {
        title = order.resources.addNewAddress;
    } else {
        title = [];
        if (shippingAddress.address1) {
            title.push(shippingAddress.address1);
        }
        if (shippingAddress.address2) {
            title.push(shippingAddress.address2);
        }

        if (title.length > 2) {
            title = title.join(' ');
        } else if (title.length == 0) {
            title = order.resources.newAddress;
        }
    }
    optionEl.text(title);

    var keyMap = {
        'data-first-name': 'firstName',
        'data-last-name': 'lastName',
        'data-address1': 'address1',
        'data-address2': 'address2',
        'data-city': 'city',
        'data-state-code': 'stateCode',
        'data-postal-code': 'postalCode',
        'data-country-code': 'countryCode',
        'data-phone': 'phone'
    };
    $.each(keyMap, function (key) {
        var mappedKey = keyMap[key];
        var mappedValue = shippingAddress[mappedKey];
        // In case of country code
        if (mappedValue && typeof mappedValue === 'object') {
            mappedValue = mappedValue.value;
        }

        optionEl.attr(key, mappedValue || '');
    });

    var giftObj = {
        'data-is-gift': 'isGift',
        'data-gift-message': 'giftMessage'
    };

    $.each(giftObj, function (key) {
        var mappedKey = giftObj[key];
        var mappedValue = safeShipping[mappedKey];
        optionEl.attr(key, mappedValue || '');
    });

    if (isSelected) {
        optionEl.attr('selected', true);
    }

    return optionEl;
}

/**
 * returns address properties from a UI form
 * @param {Form} form - the Form element
 * @returns {Object} - a JSON object with all values
 */
function getAddressFieldsFromUI(form) {
    var address = {
        firstName: $('input[name$=_firstName]', form).val(),
        lastName: $('input[name$=_lastName]', form).val(),
        address1: $('input[name$=_address1]', form).val(),
        address2: $('input[name$=_address2]', form).val(),
        city: $('input[name$=_city]', form).val(),
        postalCode: $('input[name$=_postalCode]', form).val(),
        stateCode: $('select[name$=_stateCode],input[name$=_stateCode]', form).val(),
        countryCode: $('select[name$=_country]', form).val(),
        phone: $('input[name$=_phone]', form).val()
    };
    return address;
}

module.exports = {
    methods: {
        populateAddressSummary: populateAddressSummary,
        optionValueForAddress: optionValueForAddress,
        getAddressFieldsFromUI: getAddressFieldsFromUI
    },

    showDetails: function () {
        $('.btn-show-details').on('click', function () {
            var form = $(this).closest('form');

            form.attr('data-address-mode', 'details');
            form.find('.multi-ship-address-actions').removeClass('d-none');
            form.find('.multi-ship-action-buttons .col-12.btn-save-multi-ship').addClass('d-none');
        });
    },

    addNewAddress: function () {
        $('.btn-add-new').on('click', function () {
            var $postalCodeField = $('.js-find-address');
            var $btnAddManual = $('.js-btn-add-manual');
            $('.js-btn-add-new').addClass('d-none');
            $postalCodeField.removeClass('d-none');
            $btnAddManual.removeClass('d-none');
            $('.payment-manual-address').removeClass('d-none');
        });
    },

    addBillingAddressManually: function () {
        $('.payment-manual-address').on('click', function () {
            var $el = $(this);
            var $addressSelector = $el.parents('form').find('.addressSelector');
            $addressSelector.hide();
            $('.js-btn-select-existing').removeClass('d-none');
            $('body').trigger('checkout:clearBillingForm');
        });
    },

    addManuelAddress: function () {
        $('.btn-add-manual').on('click', function () {
            var $el = $(this);
            var $addressSelector = $el.parents('form').find('.addressSelector');
            var $postalCodeField = $el.find('.js-find-address');
            $postalCodeField.removeClass('d-none');
            $('.js-btn-add-manual').addClass('d-none');
            $('.js-btn-select-existing').removeClass('d-none');
            $('.btn-show-details').removeClass('d-none');
            $addressSelector.hide();

            if ($el.parents('#dwfrm_billing').length > 0) {
                // Handle billing address case
                $('body').trigger('checkout:clearBillingForm');
                var $option = $($el.parents('form').find('.addressSelector option')[0]);
                $option.attr('value', 'new');
                var $newTitle = $('#dwfrm_billing input[name=localizedNewAddressTitle]').val();
                $option.text($newTitle);
                $option.prop('selected', 'selected');
                $el.parents('[data-address-mode]').attr('data-address-mode', 'new');
            } else {
                // Handle shipping address case
                var $newEl = $el.parents('form').find('.addressSelector option[value=new]');
                $newEl.prop('selected', 'selected');
                $newEl.parent().trigger('change');
            }
        });
    },

    selectExistingOne: function () {
        $('.btn-select-existing').on('click', function () {
            var $el = $(this);
            var $addressSelector = $el.parents('form').find('.addressSelector');
            var $newEl = $el.parents('form').find('.addressSelector option:not([disabled])');
            $addressSelector.show();
            $('.shipping-form').attr('data-address-mode', 'edit');
            $('.js-btn-select-existing').addClass('d-none');
            $('.js-btn-add-new').removeClass('d-none');
            $('.js-btn-add-manual').addClass('d-none');
            $('.js-find-address').addClass('d-none');
            $('.payment-manual-address').addClass('d-none');

            for (var i = 0; i < $newEl.length; i++) {
                var $currentOption = $($newEl[i]);
                if (!$currentOption.hasClass() && !$currentOption.hasClass('multi-shipping') && $currentOption.attr('data-first-name')) {
                    $currentOption.prop('selected', 'selected');
                    $currentOption.parent().trigger('change');
                    break;
                }
            }
        });
    },

    zipCodeChange: function () {
        $('.shippingZipCode').on('focusout', function () {
            if ($('.js-btn-add-manual').hasClass('d-none')) {
                if (!$('.js-btn-select-existing').hasClass('d-none')) {
                    var shippingZipCode = $('.shippingZipCode');
                    // This was added to requirements in AFFPT-1882
                    // When adding the address manually the field was reset keeping the original value,
                    // so the uppercase trick didn't work anymore
                    shippingZipCode.val(shippingZipCode.val().toUpperCase());
                    $('.shipping-method-list').trigger('change');
                }
            }

            if ($('.shipping-form').attr('data-address-mode') !== 'new') {
                $('.btn-find-address').trigger('click');
            }

            // This was added to requirements in AFFPT-1882
            // We need to keep uppercase always because if not address won't
            // be found and also forms won't allow us to proceed
            $(this).val($(this).val().toUpperCase());
        });
        // This was added to requirements in AFFPT-1882
        $('.billingZipCode').on('focusout', function () {
            $(this).val($(this).val().toUpperCase());
        });
    },

    findAddress: function () {
        $('.btn-find-address').on('click', function () {
            var form = $(this).closest('form');
            var pattern;
            var zipCodeText;
            var findAddressBlock = $('.js-find-address');

            if ($(form).hasClass('shipping-form')) {
                var shippingZipCode = $('.shippingZipCode');
                pattern = new RegExp(shippingZipCode.attr('pattern'));
                zipCodeText = shippingZipCode.val().toUpperCase();
            } else {
                var billingZipCode = $('.billingZipCode');
                pattern = new RegExp(billingZipCode.attr('pattern'));
                zipCodeText = billingZipCode.val().toUpperCase();
            }

            $('.shipment-selector-block .addressSelector option:not(:first)').remove();

            if (pattern && zipCodeText && pattern.test(zipCodeText)) {
                shippingZipCode.parent().removeClass('is-invalid');
                shippingZipCode.siblings('.invalid-feedback')
                    .text('')
                    .hide();
                $.ajax({
                    url: findAddressBlock.attr('data-url'),
                    type: 'post',
                    data: {
                        postCode: zipCodeText
                    },
                    success: function (data) {
                        var $addressSelector = $(form).find('.addressSelector');

                        for (var i = 0; i < data.addresses.length; i++) {
                            var address = data.addresses[i];
                            var addressLine1 = [address.line_1, address.line_2, address.line_3].filter(Boolean).join(', ');
                            var addressLine2 = [address.line_4, address.locality].filter(Boolean).join(', ');
                            var city = address.town_or_city;
                            var county = address.county;

                            var option = '<option value="' + address.stringAddress + '" ' +
                                        'data-address1="' + (addressLine1 || addressLine2) + '" ' +
                                        'data-address2="' + (addressLine1 ? addressLine2 : '') + '" ' +
                                        'data-city="' + city + '" ' +
                                        'data-postal-code="' + zipCodeText + '" ' +
                                        'data-state-code="' + county + '">' +
                                        address.stringAddress + '</option>';
                            $addressSelector.append(option);
                        }

                        if (data.addresses.length > 0) {
                            if ($(form).hasClass('shipping-form')) {
                                $('.btn-select-existing').trigger('click');
                                $('.btn-add-new').trigger('click');
                                if ($addressSelector.hasClass('notavalible')) {
                                    $addressSelector.removeClass('notavalible');
                                }
                                $('.shipment-selector-block .addressSelector option:eq(1)').attr('selected', 'selected');
                                $addressSelector.trigger('change');
                            } else if ($(form).hasClass('payment-methods-form')) {
                                if ($addressSelector.hasClass('notavalible')) {
                                    $addressSelector.removeClass('notavalible');
                                }
                            }
                        } else if ($(form).hasClass('shipping-form')) {
                            $('.shipping-method-list').trigger('change');
                        }
                    },
                    error: function (_err) {
                        // Do nothing
                    }
                });
            } else if (shippingZipCode && !pattern.test(zipCodeText)) {
                shippingZipCode.parent().addClass('is-invalid');
                shippingZipCode.siblings('.invalid-feedback')
                    .text(shippingZipCode.data('invalid'))
                    .show();
            }
        });
    }
};
