/* eslint-disable no-undef */
/**
 * Create a ecommerce event item with data retrieved from all data-item-xxx in the HTML
 * element that triggers the event
 *
 * @param {*} data - This data is sent when events are triggered i.e gtm:removeFromCart
 * In loading events that dont need user interaction dataLayer is filled with rendered data
 * in gtm cartridge scripts
 * @returns {object}
 */
function gtmCreateEcommerceItem(data, isSelectItem) {
    var item = {
        'item_id': data.itemId,
        'item_name': data.itemName,
        'item_brand': data.itemBrand,
        'item_category': data.itemCategory,
        'item_category2': data.itemCategory2,
        'item_category3': data.itemCategory3,
        'item_category4': data.itemCategory4,
        'item_category5': data.itemCategory5,
        'item_variant': data.itemVariant,
        'price': data.itemPrice,
        'quantity': data.itemQuantity,
        'discount': data.itemDiscount,
        'index': data.itemIndex
    };
    if (isSelectItem) {
        return Object.assign(item, {
            'item_list_id': (data.itemTileDomain || 'pdCarousel') + '_' + window.cachedDate,
            'item_list_name': (data.itemTileDomain || 'pdCarousel')
        });
    }
    return item;
}

// This events will be mostly used for those cases that require a more global scope
// gtmNormalizer is global and defined in global.isml
module.exports = {
    subscription: function () {
        // This was added after merge since ui is not rendered again after clicking on subscription
        // intervals so we needed to update add to cart button category 4 on clicking on tabs
        // This will work both for pdp and plp quickview
        $('body').on('click', '.js-sor-interval-pdp', function (_e, _data) {
            var intervalString = $(this).children('.number')[0].innerHTML + ' ' + $(this).children('.week')[0].innerHTML;
            $('body .add-to-cart').data('item-category4', intervalString);
        });
        $('body').on('click', '#OsfSorRefillProductYes', function (_e, _data) {
            var targetEl = $('body .js-sor-interval-pdp.active') || $('body .js-sor-interval-pdp.js-sor-interval-default');
            var gtmSorDefaultIntervalOption = $('.gtmSorDefaultIntervalOption').val();
            var gtmSorDefaultPeriodicityOption = $('.gtmSorDefaultPeriodicityOption').val();
            var intervalString = (targetEl.children('.number')[0]?.innerHTML || gtmSorDefaultIntervalOption) + ' ' + gtmSorDefaultPeriodicityOption;
            var wasOpen = $(this).attr('aria-expanded');
            if (wasOpen)
                $('body .add-to-cart').data('item-category4', undefined);
            else
                $('body .add-to-cart').data('item-category4', intervalString);
        });
    },
    selectItem: function () {
        $('body').on('click', '.product-tile', function () {
            if ($(this).parent().parent().data('swiper-slide-index') >= 0)
                $(this).data('item-index', $(this).parent().parent().data('swiper-slide-index'));
            else
                $(this).data('item-index', $(this).parent().parent().index());
            var data = Object.assign({}, $(this).data());
            // if we are adding a bundle then we are going to use bundled products instead
            // since the SEO agency asked to add separate products instead of the unitary bundle info
            var bundledProducts = data.itemBundledProducts;
            var items = [];
            // if it is a regular bundle
            if (bundledProducts) {
                bundledProducts.forEach(function (item) {
                    items.push(gtmNormalizer({
                        'item_id': item.id,
                        'item_name': item.productName,
                        'item_brand': item.range,
                        'item_category': item.gtm_product_type,
                        'item_category2': item.type_age,
                        'item_category3': item.tech_foodtype,
                        'item_category4': data.itemCategory4,
                        'item_category5': item.flavour.join(','),
                        'item_variant': item.weight,
                        'item_list_id': (data.itemTileDomain || 'pdCarousel') + '_' + window.cachedDate,
                        'item_list_name': (data.itemTileDomain || 'pdCarousel'),
                        'price': item.price.sales.decimalPrice,
                        'quantity': 1,
                        'discount': 0,
                        'index': data.itemIndex
                    }));
                });
            }
            // if not a regular bundle
            else {
                items.push(gtmNormalizer(gtmCreateEcommerceItem(data, true)));
            }
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                'event': 'select_item',
                'ecommerce': {
                    'items': items
                }
            });
        });
    },
    addToCart: function () {
        $('body').on('gtm:addToCart', function (_e, data) {
            console.log('gtm:addToCart', data);
        });
        $('body').on('click', '.add-to-cart', function () {
            var data = Object.assign({}, $(this).data());
            // if we are adding a bundle then we are going to use bundled products instead
            // since the SEO agency asked to add separate products instead of the unitary bundle info
            var bundledProducts = $(this).data('item-bundled-products');
            var items = [];
            // if it is a regular bundle
            if (bundledProducts) {
                bundledProducts.forEach(function (item) {
                    items.push(gtmNormalizer({
                        'item_id': item.id,
                        'item_name': item.productName,
                        'item_brand': item.range,
                        'item_category': item.gtm_product_type,
                        'item_category2': item.type_age,
                        'item_category3': item.tech_foodtype,
                        'item_category4': data.itemCategory4,
                        'item_category5': item.flavour.join(','),
                        'item_variant': item.weight,
                        'item_list_id': (data.itemTileDomain || 'pdCarousel') + '_' + window.cachedDate,
                        'item_list_name': data.itemTileDomain || 'pdCarousel',
                        'price': item.price.sales.decimalPrice,
                        'quantity': item.selectedQuantity * Number($('.quantity').val()),
                        'discount': 0,
                        'index': undefined
                    }));
                });
            }
            // if not a regular bundle
            else {
                if ($('.bundle-selector .tab-right').hasClass('selected')) {
                    data.itemId = $(this).data('item-id-bundle');
                    data.itemVariant = $(this).data('item-variant-bundle');
                    data.itemPrice = $(this).data('item-price-bundle');
                    data.itemDiscount = $(this).data('item-discount-bundle');
                    data.itemCategory = $(this).data('item-category-bundle');
                }
                if (!$('body #OsfSorRefillProductYes').attr('aria-expanded'))
                    data.itemCategory4 = undefined;
                items.push(gtmNormalizer(gtmCreateEcommerceItem(data)));
            }
            // pushing event to the datalayer
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                'event': 'add_to_cart',
                'ecommerce': {
                    'items': items
                }
            });
        });
    },
    removeFromCart: function () {
        $('body').on('gtm:removeFromCart', function (e, data) {
            var bundledProducts = data.itemBundledProducts;
            var items = [];
            // if it is a regular bundle
            if (bundledProducts) {
                bundledProducts.forEach(function (item) {
                    items.push(gtmNormalizer({
                        'item_id': item.id,
                        'item_name': item.productName,
                        'item_brand': item.range,
                        'item_category': item.gtm_product_type,
                        'item_category2': item.type_age,
                        'item_category3': item.tech_foodtype,
                        'item_category4': data.itemCategory4,
                        'item_category5': item.flavour.join(','),
                        'item_variant': item.weight,
                        'price': item.price.sales.decimalPrice,
                        'quantity': item.quantity * data.savedQuantity,
                        'discount': 0,
                    }));
                });
            }
            else {
                data.itemBundledProducts = undefined;
                items.push(gtmNormalizer(gtmCreateEcommerceItem(data)));
            }
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                'event': 'remove_from_cart',
                'ecommerce': {
                    'items': items
                }
            });
        });
    },
    rewardPoints: function () {
        $('body').on('gtm:rewardPoints', function (e, data) {
            dataLayer.push({
                'event': 'nm.ecommerce',
                'event name': 'rewardPoints',
                'eventCategory': 'products',
                'eventAction': 'reward points exchange',
                'eventLabel': parseInt(data.rewardPoints, 10).toString(),
            });
        });
    },
    stockistFind: function () {
        $('body').on('gtm:stockistFind', function (e, data) {
            dataLayer.push({
                'event': 'nm.event',
                'event name': 'stockist_find',
                'eventCategory': 'stockist',
                'eventAction': data.payload.postalCode,
                'eventLabel': data.count,
            });
        });
    },
    stockistClick: function () {
        $('body').on('gtm:stockistClick', function (e, data) {
            dataLayer.push({
                'event': 'nm.event',
                'event name': 'stockist_click',
                'eventCategory': 'stockist',
                'eventAction': 'click',
                'eventLabel': data.storeName || $('div[role="dialog"]').find('.store-details').data('address'),
            });
        });
    },
    stockistCall: function () {
        $('body').on('mousedown', '.storelocator-phone', function (_e, _data) {
            dataLayer.push({
                'event': 'nm.event',
                'event name': 'stockist_call',
                'eventCategory': 'stockist',
                'eventAction': 'call',
                'eventLabel': $(this).data('store-name') || $(this).closest('.store-details').data('address'),
            });
        });
    },
    stockistVisit: function () {
        $('body').on('mousedown', '.online-store', function (_e, _data) {
            dataLayer.push({
                'event': 'nm.event',
                'event name': 'stockist_visit',
                'eventCategory': 'stockist',
                'eventAction': 'visit',
                'eventLabel': $(this).data('store-name') || $(this).closest('.store-details').data('address'),
            });
        });
    },
    c2c: function () {
        $('body').on('gtm:c2c', function (_e, _data) {
            dataLayer.push({
                'event': 'nm.event',
                'event name': 'c2c',
                'eventCategory': 'Phonenumber',
                'eventAction': 'click',
                'eventLabel': $(this).data('store-name') || $(this).closest('.store-details').data('address'),
            });
        });
    },
    carousel: function () {
        $(document).ready(function () {
            if ($('.carousel').length > 0) {
                var elements = $('.carousel').find('.product-tile').parent().parent().not('[data-outofinitview]');
                var items = [];

                for (var i = 0; i < elements.length; i++) {
                    var $elem = elements.eq(i);
                    var data = Object.assign({}, $elem.find('.product-tile').data());
                    var bundledProducts = data.itemBundledProducts;

                    if (bundledProducts) {
                        bundledProducts.forEach(function (item) {
                            items.push(gtmNormalizer({
                                'item_id': item.id,
                                'item_name': item.productName,
                                'item_brand': item.range,
                                'item_category': item.gtm_product_type,
                                'item_category2': item.type_age,
                                'item_category3': item.tech_foodtype,
                                'item_category4': data.itemCategory4,
                                'item_category5': item.flavour.join(','),
                                'item_variant': item.weight,
                                'item_list_id': (data.itemTileDomain || 'pdCarousel') + '_' + window.cachedDate,
                                'item_list_name': (data.itemTileDomain || 'pdCarousel'),
                                'price': item.price.sales.decimalPrice,
                                'quantity': 1,
                                'discount': 0,
                                'index': data.itemIndex
                            }));
                        });
                    } else {
                        items.push(gtmNormalizer(gtmCreateEcommerceItem(data, true)));
                    }
                }
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    'event': 'view_item_list',
                    'ecommerce': {
                        'items': items
                    }
                });
            }
        });
        // send an event every time a new product is displayed in the carousel
        $('body').on('click', '.carousel-control-prev, .carousel-control-next', function () {
            var $elem;
            if ($(this).hasClass('carousel-control-next')) {
                $elem = $(this).parent().find('.carousel-item[tabindex=-1]');
            }
            else if ($(this).hasClass('carousel-control-prev')) {
                var totalItems = $(this).parent().find('.carousel-item').length;
                var activePos = $(this).parent().find('.carousel-item.active').data('position');
                if (activePos === 0) {
                    $elem = $(this).parent().find(`.carousel-item[data-position='${totalItems - 1}']`);
                }
                else {
                    $elem = $(this).parent().find(`.carousel-item[data-position='${activePos - 1}']`);
                }
            }
            if ($elem.length > 0) {
                var data = Object.assign({}, $elem.find('.product-tile').data());

                var bundledProducts = data.itemBundledProducts;
                var items = [];

                // if it is a regular bundle
                if (bundledProducts) {
                    bundledProducts.forEach(function (item) {
                        items.push(gtmNormalizer({
                            'item_id': item.id,
                            'item_name': item.productName,
                            'item_brand': item.range,
                            'item_category': item.gtm_product_type,
                            'item_category2': item.type_age,
                            'item_category3': item.tech_foodtype,
                            'item_category4': data.itemCategory4,
                            'item_category5': item.flavour.join(','),
                            'item_variant': item.weight,
                            'item_list_id': (data.itemTileDomain || 'pdCarousel') + '_' + window.cachedDate,
                            'item_list_name': (data.itemTileDomain || 'pdCarousel'),
                            'price': item.price.sales.decimalPrice,
                            'quantity': 1,
                            'discount': 0,
                            'index': data.itemIndex
                        }));
                    });
                }
                // if not a regular bundle
                else {
                    items.push(gtmNormalizer(gtmCreateEcommerceItem(data, true)));
                }
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    'event': 'view_item_list',
                    'ecommerce': {
                        'items': items
                    }
                });
            }
        });
    },
    swiper: function () {
        // send an event every time a new product is displayed in the swiper
        $('body').on('click', '.swiper-button-prev, .swiper-button-next', function () {
            var numElements = $(this).parent().parent().find('.swiper').attr('numberslides');
            var $elem;
            if ($(this).hasClass('swiper-button-next')) {
                var activePos = $(this).parent().parent().find('.swiper-slide-active').index();
                $elem = $(this).parent().parent().find('.swiper-slide').eq(activePos + (numElements - 1));
            }
            else if ($(this).hasClass('swiper-button-prev')) {
                $elem = $(this).parent().parent().find('.swiper-slide-active');
            }
            if ($elem.length > 0) {
                var data = Object.assign({}, $elem.find('.product-tile').data());

                var bundledProducts = data.itemBundledProducts;
                var items = [];

                // if it is a regular bundle
                if (bundledProducts) {
                    bundledProducts.forEach(function (item) {
                        items.push(gtmNormalizer({
                            'item_id': item.id,
                            'item_name': item.productName,
                            'item_brand': item.range,
                            'item_category': item.gtm_product_type,
                            'item_category2': item.type_age,
                            'item_category3': item.tech_foodtype,
                            'item_category4': data.itemCategory4,
                            'item_category5': item.flavour.join(','),
                            'item_variant': item.weight,
                            'item_list_id': (data.itemTileDomain || 'pdCarousel') + '_' + window.cachedDate,
                            'item_list_name': (data.itemTileDomain || 'pdCarousel'),
                            'price': item.price.sales.decimalPrice,
                            'quantity': 1,
                            'discount': 0,
                            'index': data.itemIndex
                        }));
                    });
                }
                // if not a regular bundle
                else {
                    items.push(gtmNormalizer(gtmCreateEcommerceItem(data, true)));
                }
                dataLayer.push({ ecommerce: null });
                dataLayer.push({
                    'event': 'view_item_list',
                    'ecommerce': {
                        'items': items
                    }
                });
            }
        });
    },
    /**
    * Attaches a click event handler to elements with the class 'js-logout' to push logout event details to the dataLayer.
    * The event handler retrieves customer GTM details from the DOM and pushes them to the dataLayer if available.
    * 
    * @event click - Triggers when an element with the class 'js-logout' is clicked.
    * @fires dataLayer#event:logout - Pushes a logout event to the dataLayer with customer details.
    * 
    * @property {string} sscid - The customer session ID retrieved from the 'data-sscid' attribute of the '#customerGTMDetails' element.
    * @property {string} number - The customer number retrieved from the 'data-number' attribute of the '#customerGTMDetails' element.
    */
    logout: function () {
        $('.js-logout').on('click', function () {
            const $GTMDetails = $('#customerGTMDetails');

            if (!$GTMDetails.length) {
                return;
            }

            const sscid = $GTMDetails.attr('data-sscid');
            const number = $GTMDetails.attr('data-number');

            if (!sscid) {
                return;
            }

            dataLayer.push({
                event: 'logout',
                ID18: sscid,
                customer_number: number
            });
        });
    },
    navigation: function () {
        $('body').on('click', '.gtm-navigation-links', function (e) {
            try {
                const currentTarget = $(e.currentTarget);
                const protocol = window.location.protocol;
                const domain = window.location.hostname;
                const footerParent = currentTarget.closest('.footer-element');
                const headerParent = $(document).width() < 768 ? currentTarget.closest('.mobile-nav').find('.nav-item.rotate-arrow-icon') : currentTarget.closest('.nav-item');
                const isFooter = footerParent.length > 0;
                const isLevel2 = !isFooter ? currentTarget.hasClass('dropdown-link') : true;
                const linkText = currentTarget.text() || '';
                dataLayer.push({
                    'event': 'navigation_click',
                    'navigation': isFooter ? 'footer' : 'header',
                    'link_url': protocol + '//' + domain + currentTarget.attr('href') || '',
                    'link_text': linkText.trim(),
                    'lvl1': isLevel2 ? isFooter ? $(footerParent[0]).find('.title').text().trim() : $(document).width() < 768 ? headerParent.find('.mobile-nav-link').text().trim() : headerParent.find('.nav-link').text().trim() : linkText.trim(),
                    'lvl2': isLevel2 ? linkText.trim() : '',
                    'lvl3': 'TBC'
                });
            } catch (e) {
                console.log(e);
            }
        });
    },
    /**
    * Handles the sign-in or registration event and pushes relevant data to the dataLayer for GTM (Google Tag Manager).
    * 
    * This function checks for the presence of customer GTM details in the DOM, determines if the current action is a login or registration,
    * and then pushes the appropriate event data to the dataLayer.
    * 
    * @returns {void}
    */
    signIn: function () {
        const $GTMDetails = $('#customerGTMDetails');

        if (!$GTMDetails.length) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const isLogin = urlParams.get('registration') === 'false';
        const isRegistration = urlParams.get('registration') === 'submitted';

        if (!isLogin && !isRegistration) {
            return;
        }

        const method = $GTMDetails.attr('data-method');
        const sscid = $GTMDetails.attr('data-sscid');
        const number = $GTMDetails.attr('data-number');

        if (!method || !sscid || !number) {
            return;
        }

        const dataToPush = {
            event: isRegistration ? 'account_registration' : 'login',
            method: method, // The signup method used. Google or email etc
            ID18: sscid, // Salesforce Contact ID for the new account
            customer_number: number, // The customer ID
            user_data: {}
        };

        const userData = {
            first_name: $GTMDetails.attr('data-first-name'),
            last_name: $GTMDetails.attr('data-last-name'),
            email: $GTMDetails.attr('data-email'),
            country: $GTMDetails.attr('data-country'),
            city: $GTMDetails.attr('data-city'),
            state: $GTMDetails.attr('data-state'),
            postal_code: $GTMDetails.attr('data-postal-code'),
            customer_status: $GTMDetails.attr('data-status'),
            customer_lifetime_spent: parseFloat($GTMDetails.attr('data-total-spent')),
        };

        Object.keys(userData).forEach(function (key) {
            if (userData[key]) {
                dataToPush.user_data[key] = userData[key];
            }
        });

        dataLayer.push(dataToPush);
    },
    failedLogin: function () {
        const loginError = $('.not-found-page').attr('data-login-error');

        if (loginError && loginError.length > 0) {
            dataLayer.push({
                event: 'login_failure',
                method: 'Google Account',
                errorMessage: loginError
            });
        }

        $('body').on('gtm:failedLogin', function (e, data) {
            dataLayer.push({
                event: 'login_failure',
                method: data.method,
                errorMessage: data.errorMessage
            });
        });
    },
    shareBasket: function () {
        $('body').on('gtm:shareBasket', function (e, data) {
            const dataToPush = {
                'event': 'ecomm_interaction',
                'journey': 'e_comm',
                'click_path': 'Share basket',
                'screen_name': 'Cart page',
            };

            if (data.customerNumber) {
                dataToPush['user_id'] = data.customerNumber;
            }

            dataLayer.push(dataToPush);
        });
    },
    landingPages: function () {
        $('body').on('gtm:recipeOverlay', function (e, data) {
            const dataToPush = {
                event: 'overlay',
                click_path: data.click_path,
                screen_name: 'recipes'
            };

            if (data.customerNumber) {
                dataToPush['user_id'] = data.customerNumber;
            }

            dataLayer.push(dataToPush);
        });

        $('body').on('gtm:clickCTA', function (e, data) {
            const dataToPush = {
                event: 'click_cta',
                click_path: data.click_path,
                screen_name: data.screen_name,
                screen_section: data.screen_section
            };

            if (data.customerNumber) {
                dataToPush['user_id'] = data.customerNumber;
            }

            dataLayer.push(dataToPush);
        });
    }
};
