/**
* Saves the consent information to the server
* @param {string} consentUrl - URL to save the consent information
* @param {string} tokenName - name of the token
* @param {string} tokenValue - value of the token
*/
function saveConsentInfo(consentUrl, tokenName, tokenValue) {
    let data = {};
    data[tokenName] = tokenValue;

    $.ajax({
        url: consentUrl,
        type: 'POST',
        dataType: 'json',
        data: data,
    })
        .then(function (data) {
            if (data && data.success) {
                $('.tracking-consent').replaceWith(data.consentInfoTemplate);
            }
        })
        .catch(function (error) {
            console.error(error);
        });
}

/**
* Waits for OneTrust to be loaded
* @returns {Promise} a Promise that resolves when OneTrust is loaded
*/
function waitForOneTrust() {
    return new Promise(function (resolve) {
        let oneTrustLoaded = setInterval(function () {
            if (typeof OneTrust === 'undefined' || !OneTrust) { // eslint-disable-line no-undef
                return;
            }

            clearInterval(oneTrustLoaded);
            resolve(OneTrust); // eslint-disable-line no-undef
        }, 1000);
    });
}

module.exports = {
    /**
    * Handles the consent tracking logic for the OneTrust SDK
    */
    bindEvents: function () {
        const $trackingConsent = $('.tracking-consent');

        waitForOneTrust().then(function (OneTrustSDK) {
            OneTrustSDK.OnConsentChanged(function (e) {
                const consentCategories = e && e.detail ? e.detail : [];
                const consentRequiredCategories = $trackingConsent.data('one-trust-consent-requiring-categories')
                    ? $trackingConsent.data('one-trust-consent-requiring-categories').split(',')
                    : []
                ;
                let consentUrl = $trackingConsent.data('accept');

                for (let category of consentRequiredCategories) {
                    // If needed categories are not seletec through OneTrust, consider consent as rejected
                    if (consentCategories.indexOf(category) < 0) {
                        consentUrl = $trackingConsent.data('reject');
                        break;
                    }
                }

                saveConsentInfo(
                    consentUrl,
                    $trackingConsent.data('tokenName'),
                    $trackingConsent.data('token')
                );
            });
        });
    },
};
