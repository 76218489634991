var cart = require('../cart/cart');
var changeMinicartIcon = require('./changeMinicartIcon');
const smartOrderRefill = require('./smartOrderRefill');
var updateMiniCart = true;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart-mobile .minicart-quantity, .minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart-mobile .minicart-link, .minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems
            });
        }
        changeMinicartIcon(count.quantityTotal);
    });

    $('.minicart').on('click', function () {
        if (!$('.search-block-content').hasClass('d-none')) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                $('.minicart-background').addClass('active');
                return;
            }

            $('.minicart .popover').addClass('show');
            $('.minicart-background').addClass('active');
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });

    $(document).on('click', 'body, .close-minicart-button', function (e) {
        if ($('.minicart').has(e.target).length <= 0 || $(e.target).hasClass('close-minicart-button')) {
            $('.minicart .popover').removeClass('show');
            $('.minicart-background').removeClass('active');
        }
    });

    smartOrderRefill.init();

    $('.minicart').on('focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
        $('.minicart-background').removeClass('active');
    });
    $('.minicart-quantity').on('DOMSubtreeModified', function () {
        var $this = $(this);

        if (parseInt($this.text(), 10) >= 1000) {
            $this.parents('.minicart-total')
                .addClass('over-1000')
                .removeClass('over-100');
        } else if (parseInt($this.text(), 10) >= 100) {
            $this.parents('.minicart-total')
                .removeClass('over-1000')
                .addClass('over-100');
        } else {
            $this.parents('.minicart-total')
                .removeClass('over-1000')
                .removeClass('over-100');
        }
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
