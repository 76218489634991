/**
* Initializes and caches DOM elements for the SAS modal.
* This function selects various elements related to the SAS modal and stores them in the $dom object for easy access.
* 
* @global
* @property {jQuery} $dom.sasModal - The main SAS modal element.
* @property {jQuery} $dom.sasModalDecline - The decline button for the SAS modal.
* @property {jQuery} $dom.sasModalConfirm - The confirm button for the SAS modal.
* @property {jQuery} $dom.sasModalIntervals - The intervals section within the SAS modal.
* @property {jQuery} $dom.sasModalShowIntervals - The button to show intervals within the SAS modal.
* @property {jQuery} $dom.sasModalIntervalButton - The interval button within the SAS modal.
* @property {jQuery} $dom.sasModalRepeatIntervalsValues - The repeat intervals values section within the SAS modal.
* @property {jQuery} $dom.checkoutBtn - The checkout button.
* @property {jQuery} $dom.sorGlobalSwitch - The global switch for the SOR modal.
*/
const $dom = {};

const initDOM = () => {
    $dom.sasModal = $('.sas-modal');
    $dom.sasModalDecline = $('.js-sas-decline', $dom.sasModal);
    $dom.sasModalConfirm = $('.sas-modal__confirm', $dom.sasModal);
    $dom.sasModalIntervals = $('.js-intervals', $dom.sasModal);
    $dom.sasModalShowIntervals = $('.js-show-intervals', $dom.sasModal);
    $dom.sasModalIntervalButton = $('.js-interval-button', $dom.sasModal);
    $dom.sasModalRepeatIntervalsValues = $('.sas-modal__repeat-intervals-values', $dom.sasModal);
    $dom.checkoutBtn = $('.checkout-btn');
    $dom.sorGlobalSwitch = $('.js-sor-switch-all');
};

/**
* Redirects the user to the checkout page.
* This function retrieves the URL from the 'href' attribute of the element with the class 'checkout-btn'
* and sets it as the current location, effectively navigating the user to the checkout page.
*/
const continueToCheckout = () => {
    window.location.href = $dom.checkoutBtn.attr('href');
};

/**
* Initializes event listeners and handles interactions for the SAS modal in the shopping cart.
* 
* This function sets up various click event listeners to manage the display and behavior of the SAS modal,
* including toggling intervals, setting active states, handling confirm, close, and decline actions, and
* managing the proceed to checkout process based on user interactions.
* 
* @returns {boolean} Returns true if the '.js-sas-modal' element is not present in the DOM, otherwise undefined.
*/
module.exports = function () {
    // Continue if '.js-sas-modal' or '.js-sor-switch-all' elements are not present in the DOM
    if (!$('.js-sas-modal').length || !$('.js-sor-switch-all').length) {
        return;
    }

    // Initialize DOM elements
    initDOM();

    $dom.sasModalShowIntervals.on('click', () => {
        $dom.sasModalIntervals.slideToggle();
    });

    $dom.sasModalIntervalButton.on('click', e => {
        $('.js-interval-button', $dom.sasModalRepeatIntervalsValues).removeClass('active');
        $(e.currentTarget).addClass('active');
    });

    $dom.sasModalDecline.on('click', () => {
        // if the user clicks the close or decline button, set the chosen interval to 4 (default value) and proceed to checkout
        sessionStorage.setItem('chosenInterval', '4');
        continueToCheckout();
    });

    $dom.sasModalConfirm.on('click', () => {
        // if the user clicks the confirm button, set the chosen interval and proceed to checkout
        const chosenInterval = $('.js-interval-button.active').attr('data-interval');
        sessionStorage.setItem('chosenInterval', chosenInterval);
        sessionStorage.setItem('proceedToCheckout', 'true');
        $dom.sorGlobalSwitch.trigger('click');
        $dom.sasModal.removeClass('show');
    });

    $dom.checkoutBtn.on('click', e => {
        e.preventDefault();
        const $sorSwitches = $('.js-sor-switch');

        // if none of the switches are checked, show the modal
        if (!sessionStorage.getItem('chosenInterval') && !$sorSwitches.is(':checked')) {
            $dom.sasModal.addClass('show');
        } else {
            continueToCheckout();
        }
    });

    if (sessionStorage.getItem('proceedToCheckout') && $('.checkout-btn').length > 0 && !$('.checkout-btn').is(':disabled')) {
        sessionStorage.removeItem('proceedToCheckout');
        continueToCheckout();
    }
};
